import React from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import ServiceCard from "../Components/ServiceCard";
import design from "../img/design.svg";
import intelligence from "../img/intelligence.svg";
import mobile from "../img/about/mobile.png";
import website from "../img/about/website.png";
import backend from "../img/about/backend.png";

function ServicesSection() {
  return (
    <InnerLayout>
      <ServicesSectionStyled>
        <Title title={"Skills"} span={"skills"} />
        <div className="services">
          <ServiceCard
            image={website}
            title={"Web Development"}
            paragraph={
              "Develop responsive websites and web apps. \n Languages: TypeScript, JavaScript, HTML, CSS \n Frameworks and Libraries: React, Gatsby.js, Next.js"
            }
          />
          <div className="mid-card">
            <ServiceCard
              image={backend}
              title={"Backend Development"}
              paragraph={
                "Design REST APIs and backend applications using Node.js and Python, as well as create GraphQL APIs. Database administration and design using SQL and NoSQL solutions (Firebase and MongoDB)"
              }
            />
          </div>
          <ServiceCard
            image={mobile}
            title={"Mobile Development"}
            paragraph={
              "Create beautiful native and cross-platform apps. \n Languages: TypeScript, JavaScript, Swift, Java, Dart \n Frameworks and Libraries: React Native, Flutter "
            }
          />
        </div>
      </ServicesSectionStyled>
    </InnerLayout>
  );
}

const ServicesSectionStyled = styled.section`
  .services {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
    @media screen and (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default ServicesSection;
