import fitmate from "../img/portImages/fitmate.jpeg";
import healthyRecipe from "../img/portImages/healthyRecipe.jpg";
import piano from "../img/portImages/piano.jpg";
import kanban from "../img/portImages/kanban.png";
import expense from "../img/portImages/expense.png";
import camera from "../img/portImages/camera.jpg";
import featureMatching from "../img/portImages/featureMatching.jpg";
import imageFilter from "../img/portImages/imageFilter.jpg";
import chem1 from "../img/portImages/chem1.jpg";
import chem2 from "../img/portImages/chem2.jpg";
import postIt from "../img/portImages/postIt.jpg";
import b4 from "../img/portImages/b4.jpg";
import esclu from "../img/portImages/esclu.jpg";
import fileWrangler from "../img/portImages/fileWrangler.jpg";
import simplyDegree from "../img/portImages/simplyDegree.jpg";
import tcp from "../img/portImages/tcp.jpg";
import space from "../img/portImages/space.jpg"; //
import draw from "../img/portImages/draw.jpg"; //
import hand from "../img/portImages/hand.jpg"; //
import textEditor from "../img/portImages/textEditor.jpg"; //
import furniture from "../img/portImages/furniture.jpg";
import car from "../img/portImages/car.jpg";
import sudoku from "../img/portImages/sudoku.jpg";
import puzzle from "../img/portImages/8puzzle.jpg";
import selfDrivingCar from "../img/portImages/selfDrivingCar.jpg";
import compiler from "../img/portImages/compiler.jpg";

const portfolios = [
  {
    id: 1,
    category: "React",
    image: textEditor,
    link1: "https://github.com/saifaldin14/VenusNotebook",
    link2: "https://github.com/saifaldin14/VenusNotebook",
    title: "Venus Notebook",
    text: "An intuitive text and code editor notebook application that allows you to create text cards and write Javascript code in the browser!",
  },
  {
    id: 2,
    category: "C++",
    image: compiler,
    link1: "https://github.com/saifaldin14/Compiler",
    link2: "https://www.google.com",
    title: "Épée Compiler",
    text: "A complete compiler for a custom language. Performs lexical, syntax and semantic analysis. Loads custom file types and creates Three Address Code",
  },
  {
    id: 3,
    category: "C++",
    image: chem1,
    link1: "https://github.com/saifaldin14/Born-Haber-Cycle-Calculator",
    link2: "https://www.google.com",
    title: "Born-Haber Cycle Calculator",
    text: "A C++ program that displays the Born-Haber cycle of an ionic compound, complete with all enthalpy and energy values",
  },
  {
    id: 4,
    category: "Unity",
    image: furniture,
    link1: "https://github.com/saifaldin14/FurnitureExplorer",
    link2: "https://www.google.com",
    title: "Furniture Explorer",
    text: "Furniture Explorer is an interactive and easy-to-use interio design system that allows the user to easily design and visualize a room from a set of pre-made furniture objects",
  },
  {
    id: 5,
    category: "React Native",
    image: fitmate,
    link1: "https://github.com/saifaldin14/FitMate",
    link2: "https://github.com/saifaldin14/FitMate",
    title: "FitMate",
    text: "React Native Fitness App to track run, workout and calorie data",
  },
  {
    id: 6,
    category: "React Native",
    image: healthyRecipe,
    link1: "https://github.com/saifaldin14/Healthy-Recipe-Builder",
    link2: "https://www.google.com",
    title: "Healthy Recipe Builder",
    text: "This is an app for a restaurant that has various features such as menu customization, featured selection, etc",
  },
  {
    id: 7,
    category: "React",
    image: piano,
    link1: "https://github.com/saifaldin14/MusicMaking",
    link2: "https://www.google.com",
    title: "Dynamic Musical Keyboard",
    text: "React App to create a virtual keyboard, supports multiple types of keyboards for different sounds",
  },
  {
    id: 8,
    category: "React",
    image: kanban,
    link1: "https://github.com/saifaldin14/KanbanApp",
    link2: "https://www.google.com",
    title: "Kanban App",
    text: "Drag and Drop Kanban application made using React with Typescript",
  },
  {
    id: 9,
    category: "React",
    image: expense,
    link1: "https://github.com/saifaldin14/ExpenseTracker",
    link2: "https://www.google.com",
    title: "Expense Tracker",
    text: "Voice Assistant Expense Tracking App",
  },
  {
    id: 10,
    category: "OpenCV",
    image: camera,
    link1: "https://github.com/saifaldin14/PhotogarphyOpenCV",
    link2: "https://www.google.com",
    title: "Photo Correction App",
    text: "OpenCV project to edit and correct hues of images",
  },
  {
    id: 11,
    category: "OpenCV",
    image: featureMatching,
    link1: "https://github.com/saifaldin14/FindingObjects",
    link2: "https://www.google.com",
    title: "Feature Matching Object Detector",
    text: "OpenCV project to detect differences in features of various images",
  },
  {
    id: 12,
    category: "OpenCV",
    image: imageFilter,
    link1: "https://github.com/saifaldin14/ImageFilters",
    link2: "https://www.google.com",
    title: "Image Filter Generator",
    text: "Using OpenCV with python 3.8 to generate a variety of image filters",
  },
  {
    id: 13,
    category: "C++",
    image: chem2,
    link1: "https://github.com/saifaldin14/Molecular-Chemistry-Visualization",
    link2: "https://www.google.com",
    title: "Molecular Chemistry Visualizer",
    text: "A program to identify the VSPER shape of a molecular compound using a Unity frontend and a C++ backend",
  },
  {
    id: 14,
    category: "Flutter",
    image: postIt,
    link1: "https://github.com/saifaldin14/Post-It",
    link2: "https://www.google.com",
    title: "Post-It",
    text: "Productivity App for capturing your daily goals. Allows you to set reminders and access the date/time calendar. It uses a SQLite database for storing data. Built in Flutter with a Python backend",
  },
  {
    id: 15,
    category: "Node.js",
    image: b4,
    link1: "https://github.com/saifaldin14/b4",
    link2: "https://www.google.com",
    title: "b4",
    text: "A full stack Node.js and Webpack program that connects to an eleastic search database to query a list of books generated from the Project Geutenberg directory or inputted by the user.",
  },
  {
    id: 16,
    category: "Node.js",
    image: esclu,
    link1: "https://github.com/saifaldin14/esclu",
    link2: "https://www.google.com",
    title: "esclu",
    text: "A Node.js CLI to bulk import book data from Project Gutenberg and store it into an ElasticSearch Database",
  },
  {
    id: 17,
    category: "Node.js",
    image: fileWrangler,
    link1: "https://github.com/saifaldin14/FileWrangler",
    link2: "https://www.google.com",
    title: "File Wrangler",
    text: "Node.js CLI application to watch for changes in a specified file system",
  },
  {
    id: 18,
    category: "React",
    image: simplyDegree,
    link1: "https://github.com/saifaldin14/SimplyDegree",
    link2: "https://www.google.com",
    title: "Simply Degree",
    text: "Simply Degree is a complete web-based degree management system for students with an interactive and intuitive UI",
  },
  {
    id: 19,
    category: "Java",
    image: tcp,
    link1: "https://github.com/saifaldin14/TCP-Simulator",
    link2: "https://www.google.com",
    title: "TCP Reliable File Transfer Simulator",
    text: "Utilizing the stop-and-wait 3.0 protocol to implement reliable data transfer on the transport layer using UDP",
  },
  {
    id: 20,
    category: "React",
    image: space,
    link1: "https://github.com/saifaldin14/Spacetagram",
    link2: "https://www.google.com",
    title: "Space-tagram",
    text: "Space-tagram is a web app that accesses NASA's APOD API to fetch astronomy images and data for a specified date period",
  },
  {
    id: 21,
    category: "React",
    image: draw,
    link1: "https://github.com/saifaldin14/DrawingApp",
    link2: "https://www.google.com",
    title: "Drawing React & Redux App",
    text: "A drawing app built with React and using Redux with Windows XP UI style",
  },
  {
    id: 22,
    category: "OpenCV",
    image: hand,
    link1: "https://github.com/saifaldin14/HandGesture",
    link2: "https://www.google.com",
    title: "Hand Gesture Recognizer",
    text: "Hand gesture recognizer using OpenCV Python and X-Box Kinect",
  },
  {
    id: 23,
    category: "Flutter",
    image: car,
    link1: "https://github.com/saifaldin14/CarLess",
    link2: "https://www.google.com",
    title: "CarLess",
    text: "CarLess is an interactive mobile game to reduce car use and promote public transport use",
  },
  {
    id: 24,
    category: "Artificial Intelligence",
    image: sudoku,
    link1: "https://github.com/danerkestey/AC-3-Sudoku",
    link2: "https://www.google.com",
    title: "AC-3 Sudoku Solver",
    text: "A sudoku solver using CSP, AC-3 and backtracking to solve randomly generated and user-inputted puzzles",
  },
  {
    id: 25,
    category: "Artificial Intelligence",
    image: puzzle,
    link1: "https://github.com/danerkestey/A-Star-Puzzle",
    link2: "https://www.google.com",
    title: "N-Puzzle A* Search Solver",
    text: "Implementing the A* Algorithm to solve n-Puzzle problem using 3 heuristics (Number of misplaced tiles, Manhattan distance and Euclidean distance)",
  },
  {
    id: 26,
    category: "Artificial Intelligence",
    image: selfDrivingCar,
    link1: "https://github.com/saifaldin14/Genetic-Algorithm-Self-Driving-Car",
    link2: "https://www.google.com",
    title: "Genetic Algorithm Self Driving Car",
    text: "A self-driving car simulation program using a simple genetic algorithm to find and train a model to navigate independently around a track",
  },
];

export default portfolios;
