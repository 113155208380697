import React from "react";
import styled from "styled-components";
import saif from "../img/about/saif.jpg";
import PrimaryButton from "./PrimaryButton";

function ImageSection() {
  return (
    <ImageSectionStyled>
      <div className="left-content">
        <img src={saif} alt="" />
      </div>
      <div className="right-content">
        <h4>
          I am <span>Saif</span>
        </h4>
        <p className="paragraph">
          I am a Computer Science and Business Adminstration Double Degree
          student with a passion for programming and software development and
          design. I have multiple years with experience with various
          technologies and languages such as React, React Native, JavaScript,
          Python and many more. I love learning, tackling new challenges and
          improving my skills.
        </p>
        {/* Do download of resume feature */}
        {/* <PrimaryButton title={"Download Cv"}>
          <a href={"../data/resume.pdf"} download>
            Dowload CV
          </a>
        </PrimaryButton> */}
      </div>
    </ImageSectionStyled>
  );
}

const ImageSectionStyled = styled.div`
  margin-top: 5rem;
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    .left-content {
      margin-bottom: 2rem;
    }
  }
  .left-content {
    width: 100%;
    img {
      width: 95%;
      object-fit: cover;
    }
  }
  .right-content {
    width: 100%;
    h4 {
      font-size: 2rem;
      color: var(--white-color);
      span {
        font-size: 2rem;
      }
    }
    .paragraph {
      padding: 1rem 0;
    }
    .about-info {
      display: flex;
      padding-bottom: 1.4rem;
      .info-title {
        padding-right: 3rem;
        p {
          font-weight: 600;
        }
      }
      .info-title,
      .info {
        p {
          padding: 0.3rem 0;
        }
      }
    }
  }
`;
export default ImageSection;
